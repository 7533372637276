let _timestampInit = (new Date).getTime();

import Vue, {createApp, nextTick} from 'vue'

import App from './App.vue'
import router from "./router";
import i18n from "./i18n";

import { vMaska } from "maska"
import pinia from "@/state/pinia";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';
import BootstrapVueNext from 'bootstrap-vue-next';
import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2';
import mitt from 'mitt';
import CountryFlag from 'vue-country-flag-next';
import vueCountryRegionSelect from 'vue3-country-region-select';

// Stores
import { useLoopbackStore } from "./state/pinia/loopback";
import { connectWebSocket } from "./ws";

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import {apiDelete, apiFile, apiGet, apiPatch, apiPost, apiPut, formatBytes, parseISO8601String} from '@/methods';

// Monaco Editor
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

let VueApp;
(
    async () => {
		const wsBus = mitt();
		wsBus.on('init', () => {
			console.info('[WS] Wroom wroom');
		});

		let apiUnavailable = false;

        VueApp = createApp(App);
		VueApp.provide('wsBus', wsBus);

		wsBus.on('connected', () => {
			console.info('[WS] Connected');
		});
		wsBus.on('disconnected', async () => {
			console.info('[WS] Disconnected');
			async function reconnectWebsocket() {
				wsBus.emit('reconnect');
				let reauthRequest = await apiGet('v1/@me', {autoErrorHandler: false});
				if(reauthRequest.status) {
					wsBus.emit('wsk', reauthRequest.response.wsk);
				}
			}
			setTimeout(() => {
				reconnectWebsocket();
			}, 1000 * 5);
		});
		connectWebSocket(wsBus, process.env.VUE_APP_ROOT_WS);

		VueApp.use(pinia);
		/*
		 * Seed Store
		 */
		let bootstrapRequest = await apiGet('v1/@me', {autoErrorHandler: false});
		if(!bootstrapRequest.result) {
			if([418, 500, 501, 502, 503].includes(bootstrapRequest.status) || bootstrapRequest.status === null || bootstrapRequest.status === undefined) {
				apiUnavailable = true;
			}
		} else {
			const loopback = useLoopbackStore();
			loopback.setAuth('true');
			loopback.setId(bootstrapRequest.response.id);
			loopback.setRole(bootstrapRequest.response.structure.role);
			loopback.setPersona(bootstrapRequest.response.persona);
			wsBus.emit('wsk', bootstrapRequest.response.wsk);
		}

		// Core plugins
		VueApp.use(router);
		VueApp.use(i18n);

		// Third party
		VueApp.use(VueMonacoEditorPlugin);
		VueApp.use(BootstrapVueNext);
		VueApp.use(VueApexCharts);
		VueApp.use(vClickOutside);
		VueApp.component('country-flag', CountryFlag);
		VueApp.use(vueCountryRegionSelect);

		VueApp.use(registerScrollSpy);
		VueApp.use(VueSweetalert2);
		// test

		// Load custom methods
        VueApp.mixin({
            apiGet: apiGet,
            apiFile: apiFile,
            apiPost: apiPost,
            apiPut: apiPut,
            apiPatch: apiPatch,
            apiDelete: apiDelete,
            parseDate: parseISO8601String,
			formatBytes: formatBytes
        })

		function handleGlobalWS(data) {
			if(data.event === 'alert') {
				Swal.fire({
					icon: 'error',
					text: data.data.message
				});
			}
		}
		wsBus.emit('subscribe', {topic: 'global', handler: handleGlobalWS});

		// Delay app render artificially to ensure store is populated and seeded
        setTimeout(() => {
			VueApp.mount('#app');
			if(apiUnavailable) {
				router.push({name: '500'});
			} else if(bootstrapRequest.result && bootstrapRequest.response._next && router.currentRoute.value.name !== 'logout') {
				router.push({name: bootstrapRequest.response._next});
			}
		}, 50);
    }
)();

export default {VueApp: VueApp, i18n: i18n};