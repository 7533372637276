<script>

import {useLoopbackStore} from "@/state/pinia/loopback";
import {EventBus} from "@/events";

export default {
  name: 'App',
	computed: {

	},
  components: {

  },
	methods: {
		async handleError(error) {
			this.error = error;
			console.log(error);
			this.$refs.errorModal.show();
		}
	},
	async mounted() {

	},
	async created() {
		const loopback = useLoopbackStore();
		if(loopback.displayErrors) {
			EventBus.on('error', this.handleError);
			console.log('Error handler registered');
		} else {
			console.log('No error handler registered');
		}
	},

	data() {
		return {
			error: null
		};
	},
}
</script>

<template>
	<div id="loopback-app">
		<b-modal class="" size="lg" hide-footer hide-header ref="errorModal">
			<template v-if="error">
				<h5 class="m-0 p-0 text-danger">
					<span class="text-code">{{ error.requestUrl }} [{{ error.statusCode }}]</span>
				</h5>
				<hr/>
				<pre>{{error.response}}</pre>
			</template>
		</b-modal>
		<router-view></router-view>
	</div>
</template>
